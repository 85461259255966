import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import { graphql } from 'gatsby'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/pole-pruners/homeowner-pole-pruners/',
    imageId: 'homeownerPolePrunersImage',
    name: 'Homeowner Pole Pruners',
    alt: 'STIHL Homeowner Pole Pruners',
  },
  {
    link: '/stihl/pole-pruners/professional-pole-pruners/',
    imageId: 'proPolePrunersImage',
    name: 'Professional Pole Pruners',
    alt: 'STIHL Professional Pole Pruners',
  },
  {
    link: '/stihl/pole-pruners/battery-pole-pruners/',
    imageId: 'batteryPolePrunersImage',
    name: 'Battery Pole Pruners',
    alt: 'STIHL Battery Pole Pruners',
  },
]

const PolePrunersPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Pole Pruners | Hutson Inc</title>
        <meta
          name='description'
          content='Get to those hard to reach branches with STIHL pole pruners, with battery and gas options for homeowners and professionals.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Pole Pruners',
                'item': 'https://www.hutsoninc.com/stihl/pole-pruners/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Pole Pruners' />

      <Content>
        <Floater items={floaterItems} />

        <Promos data={promos} type='STIHL Pole Pruner' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query polePrunersStihlQuery($category: String = "pole-pruners") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/pole-pruners/pole-pruners-bg.jpg" }) {
      ...FullWidthImage
    }
    homeownerPolePrunersImage: file(
      relativePath: { eq: "stihl/pole-pruners/homeowner-pole-pruners.jpg" }
    ) {
      ...FloatingGridImage
    }
    proPolePrunersImage: file(
      relativePath: { eq: "stihl/pole-pruners/professional-pole-pruners.jpg" }
    ) {
      ...FloatingGridImage
    }
    batteryPolePrunersImage: file(
      relativePath: { eq: "stihl/pole-pruners/battery-pole-pruners.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default PolePrunersPage
